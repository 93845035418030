import React from "react";
import Content from '../views/Dealership/Main';

export default ({location}) => (
  <Content location={location} seo={{
    lang: 'da-DK',
    title: "Connected car tjenester til  bilforhandlere",
    description: 'Vær mere proaktiv med bilforhandlerens løsning, som en del af connected car-tjenester, letter administrationarbejdet og kundeservice.',
    meta: [{
      name: 'keywords',
      conent: 'Bilforhandler'
    }]
  }}/>
);